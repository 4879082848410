<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      :title="title"
      :route-father="routeFather"
      :routeFatherName="pathName"
      :otherGoBackParams="{ params: { filters: this.filters } }"
    >
      <template v-slot:header-actions>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <SimpleList
            identifier-key="key"
            :items="rightMenu"
            @item-click="handleItemClick"
          ></SimpleList>
        </v-menu>
      </template>
      <template v-slot:content>
        <v-subheader
          class="justify-center align-center font-italic font-weight"
          style="font-size: 30px"
          >Riepilogo Movimento</v-subheader
        >
        <div class="ma-4 justify-center align-center">
          <v-row
            v-if="movementType == 'import' || movementType == 'order'"
            no-gutters
            class="justify-center align-center"
          >
            <v-col class="ml-15 mr-15" cols="3">
              <v-text-field
                v-if="movementType == 'import'"
                v-model="fileName"
                disabled
                dense
                filled
                label="Nome File"
                prepend-icon="mdi-package-variant"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-else-if="movementType == 'order'"
                v-model="orderCode"
                disabled
                dense
                filled
                label="Codice Ordine"
                prepend-icon="mdi-package-variant"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col class="ml-15" cols="3">
              <v-text-field
                v-if="movementCodes.length==0"
                v-model="movementCode"
                disabled
                dense
                filled
                label="Codice Movimento Generato"
                prepend-icon="mdi-package-up"
                hide-details="auto"
              ></v-text-field>
              <v-select
                v-else
                v-model="movementCodesSelected"
                :items="movementCodes"
                :return-object="false"
                disabled
                dense
                filled
                multiple
                item-text="code"
                item-value="id"
                label="Codici Movimenti Generati"
                prepend-icon="mdi-package-up"
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            v-else-if="movementType == 'scanner'"
            class="justify-center align-center"
          >
            <div class="d-flex justify-center align-center">
              <v-text-field
                v-model="movementCode"
                disabled
                dense
                filled
                label="Codice Movimento Generato"
                prepend-icon="mdi-package-up"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div v-if="false" class="ml-4 mr-4">
          <v-subheader class="font-italic font-weight-light"
            >Riepilogo Movimento</v-subheader
          >
          <v-row no-gutters>
            <v-row no-gutters>
              <v-col cols="3">
                <v-text-field
                  v-model="codeMovement"
                  :placeholder="codePlaceHolder"
                  disabled
                  dense
                  filled
                  label="Codice Movimento"
                  prepend-icon="mdi-code-tags"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-textarea
                  disabled
                  v-model="descMovement"
                  :placeholder="descPlaceHolder"
                  dense
                  filled
                  label="Descrizione Movimento"
                  prepend-icon="mdi-code-tags"
                  hide-details="auto"
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col class="ml-5" cols="4">
                <v-text-field
                  disabled
                  v-model="descMovement"
                  :placeholder="descPlaceHolder"
                  dense
                  filled
                  label="Data Movimento"
                  prepend-icon="mdi-calendar"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>
          <v-row no-gutters>
            <v-row class="mt-3" no-gutters>
              <v-col cols="3">
                <v-text-field
                  v-model="priceTotSelling"
                  dense
                  filled
                  disabled
                  label="Totale Prezzo di Vedita"
                  prepend-icon="mdi-currency-eur"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="3">
                <v-text-field
                  v-model="priceTotPurchase"
                  dense
                  filled
                  disabled
                  label="Totale Movimento"
                  prepend-icon="mdi-currency-eur"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col class="ml-5" cols="3">
                <v-text-field
                  v-model="priceTotNetPurchase"
                  dense
                  filled
                  disabled
                  label="Totale Netto Movimento"
                  prepend-icon="mdi-currency-eur"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>
        </div>
        <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
          <v-subheader
            class="justify-center align-center font-italic font-weight-light"
            >Lista prodotti caricati in questo movimento
          </v-subheader>
          <MovementItemDataTable
            :show-select="false"
            :headers="headersOrderItem"
            :items="movementItemsSelected"
            :loading="loading"
            item-key="id"
            loading-text="Caricamento movimento"
            :translator="translator"
            fixed-header
            :show-actions="true"
            :height="dataTableHeight"
            :page.sync="page"
            :row-per-page.sync="rowPerPage"
            :total-pages.sync="totalPages"
          >
            <template v-slot:custom-qty="{ item }">
              <v-chip
                class="aling-center justify-center"
                v-if="item['type'] == 'upload'"
                color="success"
              >
                {{ item["qty"] }}</v-chip
              >
              <v-chip class="aling-center justify-center" v-else color="error">
                {{ item["qty"] ? item["qty"] : 0 }}</v-chip
              >
            </template>

            <template v-slot:custom-createdAtTime="{ item }">
              <DateTimeFormattor
                :with-time="true"
                :with-date="false"
                v-model="item['createdAt']"
                not-specified-text="Non specificato"
              ></DateTimeFormattor>
            </template>

            <template v-slot:custom-type="{ item }">
              <v-icon
                class="d-flex flex-wrap aling-center justify-center"
                v-if="item['type'] === 'upload'"
                color="success"
              >
                mdi-package-up</v-icon
              >
              <v-icon
                class="d-flex flex-wrap aling-center justify-center"
                v-else-if="item['type'] === 'unload'"
                color="error"
              >
                mdi-dolly</v-icon
              >
            </template>

            <template v-slot:actions="{ item }">
              <div style="min-width: 100px">
                <v-btn icon class="ml-1" @click="detailItem(item)">
                  <v-icon>mdi-clipboard-list</v-icon>
                </v-btn>
              </div>
            </template>
          </MovementItemDataTable>
        </div>
      </template>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Chiudi</v-btn>
      </template>
    </FullScreenDialog>
  </div>
</template>

<script>
import { SimpleList } from "likablehair-ui-components";
import MovementItemDataTable from "@/components/common/TypeDataTable";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import movementService from "@/services/warehouse/movements.service.js";
export default {
  name: "MovementDetail",
  components: {
    FullScreenDialog,
    SimpleList,
    MovementItemDataTable,
    DateTimeFormattor,
  },
  props: {
    pathToGoBack: {
      type: String,
      default: "/warehouse/orders/list",
    },
    pathName: {
      type: String,
    },
    filters: {
      type: Array,
    },
  },
  mounted() {
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
    this.fetchHeaders();
    if (
      !!this.$route.params.movementId &&
      (!this.$route.params.orderId || !this.$route.params.importId)
    ) {
      this.movementId = this.$route.params.movementId;
      this.movementType = "scanner";
      this.fetchMovement(this.movementId);
    } else if (!!this.$route.params.orderId) {
      this.movementType = "order";
      this.orderId = this.$route.params.orderId;
      this.fetchMovementsByOrder(this.orderId);
    } else if (!!this.$route.params.importId) {
      this.movementType = "import";
      this.importId = this.$route.params.importId;
      this.fetchMovementByImport(this.importId);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
  data() {
    return {
      movementType: "",
      loading: false,
      routeFather: this.pathToGoBack,
      dialog: true,
      title: "",
      rightMenu: [],
      orderId: undefined,
      importId: undefined,
      movement: undefined,
      movementItems: [],
      movementItemsSelected: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      dataTableHeightValue: 300,
      resizeListener: undefined,
      headersOrderItem: [],
      movementCode: undefined,
      movementCodes: [],
      movementCodesSelected: [],
      orderCode: undefined,
      fileName: undefined,
    };
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  methods: {
    detailItem(movement) {
      this.$router.push({
        name: "ItemHome",
        params: {
          id: movement.itemId,
        },
        // query: {
        //   pathToGoBack:'',
        // },
      });
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    translator(field, value) {
      if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleItemClick() {},
    fetchHeaders() {
      movementService._fields_items_small().then((headers) => {
        this.headersOrderItem = headers;
      });
    },
    fetchMovement(movementId) {
      movementService.get(movementId).then((movement) => {
        this.title = "Movimento " + movement.code;
        this.movementCode = movement.code;
        this.movement = movement;
        this.fetchMovementItems({}, movement.id);
      });
    },
    fetchMovementsByOrder(orderId) {
      movementService.getMovementsByOrderId(orderId).then((movements) => {
        this.title = "Movimento " + movements[0].code;
        this.movementCodes = movements;
        this.movementCodesSelected = [...this.movementCodes]
        this.orderCode = movements[0].order.code;
        this.movement = movements[0];
        movements.map((movement) => {
          movement.movementItems.map((movementItem) => {
            movementItem.movementCode = movement.code
            this.movementItemsSelected.push(movementItem)
            })
        })
        this.movementItems = [...this.movementItemsSelected]
      });
    },
    fetchMovementByImport(importId) {
      movementService.getMovementByImportId(importId).then((movement) => {
        this.title = "Movimento " + movement.code;
        this.movementCode = movement.code;
        this.fileName = movement.import.fileName;
        this.movement = movement;
        this.fetchMovementItems({}, movement.id);
      });
    },
    fetchMovementItems(filters, movementId) {
      this.loading = true;
      movementService
        .getMovementItemsByMovementId(
          this.page,
          this.rowPerPage,
          filters,
          movementId
        )
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page) {
            this.page = this.totalPages;
          }
          this.movementItemsSelected = results;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>